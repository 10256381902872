import React, { useState, useEffect } from 'react';

const ResponsiveImage = ({ srcDesktop, srcMobile, alt }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const imageSrc = windowWidth <= 768 ? srcMobile : srcDesktop;

  return <img src={imageSrc} alt={alt} />;
};

export default ResponsiveImage;
