const Footer = () => {
    return (
        <>
            <footer>
                2024 Wyrd Digital LLC. All Rights Reserved.
            </footer>
        </>
    );
}

export default Footer;