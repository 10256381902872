import React, { useState } from 'react';
import ResponsiveImage from "../components/responsiveImage";
import Footer from "../components/footer";
import Nav from "../components/nav";
import '../App.css';
// import boomboxImage from "../assets/radio.png";
// import controlsImage from "../assets/controls.png";


const Home = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState('');

  const toggleMenu = () => {
    setIsNavOpen(!isNavOpen);
    if (isNavOpen) setActiveDropdown(''); // Close any open dropdown when menu closes
  };

  const handleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? '' : dropdown);
  };

  return (
    <>
      <main className="main-background">
        <button className="menu-button" onClick={toggleMenu}>
          &#x2630; {/* Unicode for hamburger menu icon */}
        </button>
        <button className="connect-button">
          CONNECT
        </button>
        <Nav isVisible={isNavOpen} activeDropdown={activeDropdown} handleDropdown={handleDropdown} />
        {!isNavOpen && (
          <>
            <div className="logo">
              <ResponsiveImage srcDesktop="/lastmain.png" srcMobile="/lastmain.png" alt="Logo" />
            </div>
            {/* <div className="bottom-left-images">
              <img id='controls' src={controlsImage} alt="Music Controls" />
              <img id='boombox' src={boomboxImage} alt="Boombox" />
            </div> */}
            <Footer />
          </>
        )}
      </main>
    </>
  );
}

export default Home;
