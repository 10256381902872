// Assuming Nav.js should look like this:

import React from 'react';

const Nav = ({ isVisible }) => {
    return (
        <div className={`navbar mt-1 ${isVisible ? "show" : ""}`}>
            <ul>
                <li>ORIGIN</li>
                <li className="dropdown">
                    COMMUNITY <span className="dropdown-indicator">▼</span>
                    <div className="dropdown-content">
                        <a href="/">Discord</a>
                        <a href="/">Clubs</a>
                    </div>
                </li>
                <li className="dropdown">
                    SOCIAL MEDIA <span className="dropdown-indicator">▼</span>
                    <div className="dropdown-content">
                        <a href="/">Twitter</a>
                        <a href="/">Instagram</a>
                    </div>
                </li>
                <li className="dropdown">
                    BUY <span className="dropdown-indicator">▼</span>
                    <div className="dropdown-content">
                        <a href="/">Magic Eden</a>
                        <a href="/">Tensor</a>
                    </div>
                </li>
            </ul>
            <button className="connect-button">CONNECT</button>
        </div>
    );
};

export default Nav;
